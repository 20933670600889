<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員等級管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>會員列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="isFetching">
      <b-card>
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">{{ `已擁有${customerLevel.name}會員等級的會員` }}</h4>

        <div class="d-flex justify-content-end align-items-center">
          <div class="list-group-item d-flex align-items-center border-0">
            <b-dropdown
              class="flex-shrink-0"
              variant="inverse-info"
            >
              <template #button-content>
                <span class="mdi mdi-download"></span> 資料匯出
              </template>
              <b-dropdown-item @click="exportCustomers('excel')">下載 EXCEL</b-dropdown-item>
              <b-dropdown-item @click="exportCustomers('csv')">下載 CSV</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="list-group-item d-flex align-items-center border-0">
            <b-button
              variant="inverse-info"
              class="flex-shrink-0 mb-2 mb-xl-0"
              :to="{ name: 'CustomerLevelsLog', params: { id: $route.params.id } }"
            >
              <span class="mdi mdi-note-multiple"></span>異動紀錄
            </b-button>
          </div>
          <div v-if="customerLevel.is_line_module" class="list-group-item d-flex align-items-center border-0">
            <b-button
              variant="inverse-info"
              class="flex-shrink-0 mb-2 mb-xl-0"
              @click="showImportModal"
            >
             <span class="mdi mdi-cloud-upload"></span> 批次上傳
            </b-button>
          </div>
          <div v-if="customerLevel.is_line_module" class="row">
            <StaffAddCustomer
              ref="staffAddCustomer"
              add-button-text="加入會員"
              @bind="bindCustomer"
              :sourceType="customerMerchantType"
            ></StaffAddCustomer>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="customers"
              :fields="fields"
            >
              <template #cell(avatar_url)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
                    <b-avatar
                      :src="data.item.avatar_url"
                      variant="secondary"
                      size="2rem"
                    ></b-avatar>
                  </router-link>
                </div>
              </template>
              <template #cell(name)="data">
                <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >{{ data.item.name | hiddenString(20) }}</router-link>
              </template>
              <template #cell(action)="data">
                <div class="d-flex justify-content-center">
                  <b-button class="mr-2" variant="inverse-info" size="sm" v-b-modal="'modal-choose-customer-time'" @click="chooseCustomer(data.item)">
                    設定
                  </b-button>
                  <b-button variant="inverse-danger" size="sm" @click="unbindCustomer(data.item)">
                    移除會員
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </div>

        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card>
    </b-overlay>
    <b-modal id="modal-batch-assign" title="批次匯入">
      <b-overlay :show="showLoadingUpload">
        <template v-if="batchAssignStep == 1">
          <div class="form-group">
            <p class="text-danger">依 LINE ID、手機號碼 順序比對會員</p>
            <b-form-file plain v-model="batchAssignFile"  placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
            <b-button
              class="mt-2"
              size="sm"
              variant="outline-primary"
              href="/excel/batch_assign_redeem_code.xlsx"
            >
              <i class="fa fa-file-text"></i>下載範例檔案
            </b-button>
          </div>
        </template>
        <template v-else-if="batchAssignStep == 2">
          <div class="form-group">
            <p>
              上傳資料筆數：{{ importCustomerPreview.total_member_count }}<br/>
              對應會員數量：{{ importCustomerPreview.valid_member_count }}<br/>
            </p>
            <p v-if="importCustomerPreview.valid_member_count != importCustomerPreview.total_member_count" class="text-danger">
              上傳資料數量與對應會員數量不同，請問是否確定匯入
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-danger" size="sm" class="float-right" @click="cancel()">
          取消
        </b-button>
        <b-button
          v-if="batchAssignStep == 1"
          size="sm"
          variant="outline-primary"
          @click="previewCustomer"
        >
          下一步
        </b-button>
        <b-button v-if="batchAssignStep == 2" size="sm" variant="outline-primary" @click="goBackBatchAssign">回上一步</b-button>
        <b-button
          v-if="batchAssignStep == 2"
          size="sm"
          variant="outline-primary"
          @click="importCustomer"
        >
          確定匯入
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-choose-customer-time"
      :title="customerTimeTitle"
      centered
      @hidden="resetModal"
      @ok="handleEditGroupBranch"
    >
      <form ref="form" @submit.stop.prevent="handleEditGroupBranch">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="* 開始時間"
        >
          <div class="row">
            <div class="col-12 col-xl-6">
              <datepicker
                placeholder="選擇日期"
                v-model="startAt.date"
                bootstrap-styling
                format="yyyy-MM-dd"
                :language="zh"
                :input-class="v$.startAt.date.$error ? 'is-invalid' : ''"
              ></datepicker>
              <b-form-invalid-feedback :state="!v$.startAt.date.$error">
                此欄位為必填
              </b-form-invalid-feedback>

            </div>

            <div class="col-12 col-xl-6">
              <vue-timepicker
                placeholder="選擇時間"
                :input-class="[
                              'form-control',
                              {
                                'is-invalid':
                                  v$.startAt.time.HH.$error ||
                                  v$.startAt.time.mm.$error
                              },
                            ]"
                v-model="startAt.time"
              ></vue-timepicker>
              <b-form-invalid-feedback
                :state="!v$.startAt.time.HH.$error || !v$.startAt.time.mm.$error"
              >
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="* 結束時間"
        >
          <div class="row">
            <div class="col-12 col-xl-6">
              <datepicker
                placeholder="選擇日期"
                v-model="endAt.date"
                bootstrap-styling
                format="yyyy-MM-dd"
                :language="zh"
                :input-class="v$.endAt.date.$error ? 'is-invalid' : ''"
              ></datepicker>
              <b-form-invalid-feedback :state="!v$.endAt.date.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </div>

            <div class="col-12 col-xl-6">
              <vue-timepicker
                placeholder="選擇時間"
                :input-class="[
                              'form-control',
                              {
                                'is-invalid':
                                  v$.startAt.time.HH.$error ||
                                  v$.startAt.time.mm.$error,
                              },
                            ]"
                v-model="endAt.time"
              ></vue-timepicker>
              <b-form-invalid-feedback
                :state="!v$.endAt.time.HH.$error || !v$.endAt.time.mm.$error"
              >
                此欄位為必填
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form-group>
      </form>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          取消
        </b-button>
        <b-button size="sm" variant="success" @click="ok()">
          確認送出
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import { BAvatar } from "bootstrap-vue";
import customerLevelApi from "@/apis/customer-levels";
import moment from "moment";
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";

import "bootstrap-vue/dist/bootstrap-vue.css";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { mapGetters } from "vuex";
import {format, set} from "date-fns";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import Swal from "sweetalert2";
import deepGet from "@/utils/deepGet";

export default {
  components: {
    CustomPagination,
    BAvatar,
    StaffAddCustomer,
    Datepicker,
    VueTimepicker,
  },
  mixins: [paginationMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      startAt: {
        date: {
          required
        },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      endAt: {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
    };
  },
  data() {
    return {
      zh,
      totalRows: 0,
      isFetching: false,
      customerLevel: {
        name: "",
      },
      showLoadingUpload: false,
      batchAssignFile: null,
      batchAssignStep: 1,
      importCustomerPreview: {
        total_member_count: 0,
        valid_member_count: 0,
      },
      customers: [],
      fields: [
        { key: "avatar_url", label: "" },
        { key: "name", label: "LINE 名稱" },
        { key: "outer_code", label: "平台ID" },
        { key: "mobile_phone", label: "手機" },
        { key: "email", label: "Email" },
        {
          key: "birthday",
          label: "生日",
          formatter: (value) => {
            return value ? moment(value).format("YYYY-MM-DD") : "";
          },
        },
        { key: "action", label: "操作" },
      ],
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
      },
      customerMerchantType: "line",
      selectedCustomer: null,
      customerTimeTitle: null,
      startAt: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      endAt: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      isSubmit: false,
    };
  },

  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },

  mounted() {
    this.customerMerchantType = this.getModuleConfig("customer", "customer_level.customer_merchant_type") ?? 'line'
    this.fetchCustomerLevel();
    this.fetchCustomers();
    this.initialized = true;
  },

  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
  },

  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchCustomers();
    },
    selectedCustomer: {
      handler(newVal) {
        if (newVal) {
          // 當 selectedCustomer 改變時，更新 customerTimeTitle
          this.customerTimeTitle = `${newVal.name} - ${this.customerLevel.name} 會員期限設定`;
        } else {
          // 如果選擇的客戶被清空
          this.customerTimeTitle = null;
        }
      },
      immediate: true, // 初次進入時也執行一次
    },
  },

  methods: {
    deepGet,
    showBatchAssignModal() {
      this.batchAssignStep = 1;
      this.$bvModal.show('modal-batch-assign')
    },
    async fetchCustomerLevel() {
      const { data } = await customerLevelApi.show(
        this.$route.params.id
      );
      this.customerLevel = data.data
    },
    async fetchCustomers() {
      try {
        this.isFetching = true;
        const { data } = await customerLevelApi.getCustomers(
          this.$route.params.id,
          { ...this.query }
        );
        this.customers = data.data;
        this.totalRows = data.meta.total;
      } catch (e) {
        console.log(e)
      }
      this.isFetching = false
    },
    async exportCustomers(type) {
      try {
        this.isFetching = true;
        let params = { type: type === 'csv' ? 'csv' : 'excel' };
        await customerLevelApi.exportCustomers(this.$route.params.id, params).then(() => {
          this.$swal({
            title: '成功',
            text: "可至“我的主控台”查看紀錄及下載檔案",
            showCancelButton: true,
            cancelButtonText: '確認',
            type: 'success',
            confirmButtonText: '前往查看',
          }).then((result)=>{
              if (result.value) {
                this.$router.push({
                  name: "MyConsoleDownloadFileList",
                  params: { org_code: this.$route.params.org_code },
                });
              }
            });
        });
      } catch (e) {
        console.log(e)
      }
      this.isFetching = false
    },
    async bindCustomer(customer) {
      this.$swal({
        title: "確定要加入會員嗎",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>消費者姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認加入",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.doBindCustomer(customer)
          } catch (e) {
            console.log(e)
          } finally {
            this.$refs.staffAddCustomer.refreshSearch()
            this.$refs.staffAddCustomer.closeModal()
          }
        }
      })
    },
    async doBindCustomer(customer) {
      this.isFetching = true
      try {
        await customerLevelApi.bindCustomer(this.$route.params.id, customer.id)
        await this.fetchCustomers()
      } catch (error) {
        console.error(error);

        this.$swal(
          "加入失敗",
          `${customer.name} 加入失敗`,
          "warning"
        );
      }
      this.isFetching = false
    },
    async unbindCustomer(customer) {
      const result = await this.$swal({
        title: "移除會員",
        text: `確定要將 ${customer.name} 移除嗎?`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "移除",
        reverseButtons: true,
      })
      if (! result.value) {
        return
      }

      this.isFetching = true
      try {
        await customerLevelApi.unbindCustomer(this.$route.params.id, customer.id)
        await this.fetchCustomers()
      } catch (error) {
        console.error(error);

        this.$swal(
          "解除失敗",
          `${customer.name} 解除失敗`,
          "warning"
        );
      }
      this.isFetching = false
    },
    showImportModal() {
      this.batchAssignStep = 1;
      this.$bvModal.show('modal-batch-assign')
    },
    importFailHandler(e) {
      console.error(e);
      if (e.response.status === 422 && e.response.data.message[0]) {
        this.$swal.fire({
          title: "檔案內容有誤",
          type: "error",
          text: e.response.data.message[0] ?? null,
        });
      } else {
        this.$swal.fire({
          title: "上傳失敗",
          type: "error",
        });
      }
    },
    goBackBatchAssign() {
      this.batchAssignFile = null
      this.batchAssignStep = 1
    },
    async previewCustomer() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.batchAssignFile);

      try {
        let { data } = await customerLevelApi.importCustomerPreview(this.$route.params.id, formData);
        this.importCustomerPreview.total_member_count = data.data.total_members;
        this.importCustomerPreview.valid_member_count = data.data.total_valid_members;

        this.batchAssignStep = 2;
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
      }
    },
    chooseCustomer(data) {

      this.v$.$reset();
      this.selectedCustomer = data;

      if (data?.logs?.[0]) {
        const log = data.logs[0];

        if (log.start_at) {
          this.startAt = this.dateStringToDate(log.start_at);
        }

        if (log.end_at) {
          this.endAt = this.dateStringToDate(log.end_at);
        }
      }
    },
    async importCustomer() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.batchAssignFile);

      try {
        await customerLevelApi.importCustomer(this.$route.params.id, formData);
        this.$swal.fire({ title: "上傳成功", type: "success" });

        this.fetchCustomers()
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-batch-assign");
      }
    },
    resetModal() {
      this.v$.$reset();
      this.selectedCustomer = null;
      this.startAt = {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      };
      this.endAt = {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      };
      this.isSubmit = false;
    },
    async handleEditGroupBranch(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isSubmit = true;

      const result = await this.v$.$validate();

      if (!result) return;

      let startAt    = this.formatDatetime(this.startAt);
      let endAt      = this.formatDatetime(this.endAt);

      // 轉換為 Date 物件（注意：要先將日期字串格式轉換為符合 Date 構造函數的格式）
      let startDate = new Date(startAt.replace(" ", "T")); // 替換空格為 T，使格式符合 ISO 8601
      let endDate = new Date(endAt.replace(" ", "T"));

      // 比較大小
      if (startDate > endDate) {
        this.$swal(
          "錯誤",
          '開始時間不得大於結束時間',
          "error"
        );

        return;
      }

      let customerId = this.selectedCustomer.id;
      let levelId    = this.customerLevel.id;
      let response = await customerLevelApi.upCustomerLevelLogLogTime(
        levelId,
{
          customer_id : customerId,
          start_at    : startAt,
          end_at      : endAt
        }
      );

      this.showLoadingUpload = false;

      if (response.status !== 200) {
        Swal.fire({
          title: '錯誤',
          type: 'error',
          text: '更新失敗'
        })
        return;
      }

      this.resetModal();
      this.fetchCustomers();
      this.$bvModal.hide("modal-choose-customer-time");
    },
    formatDatetime(datetime) {
      return format(
        set(new Date(datetime.date), {
          hours: Number(datetime.time.HH),
          minutes: Number(datetime.time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    dateStringToDate(dateString) {
      if (!dateString) {
        return {
          date: null,
          time: {
            HH: null,
            mm: null,
          },
        };
      }

      const dateTime = new Date(dateString);

      if (isNaN(dateTime.getTime())) {
        console.error("Invalid date string:", dateString);
        return {
          date: null,
          time: {
            HH: null,
            mm: null,
          },
        };
      }

      return {
        date: `${dateTime.getFullYear()}-${String(dateTime.getMonth() + 1).padStart(2, "0")}-${String(dateTime.getDate()).padStart(2, "0")}`,
        time: {
          HH: String(dateTime.getHours()).padStart(2, "0"),
          mm: String(dateTime.getMinutes()).padStart(2, "0"),
        },
      };
    },
  },
};
</script>

<style scoped>
  /* 讓內部 <input> 的樣式隨驗證狀態改變 */
  .vue__time-picker input.is-invalid {
    border-color: red !important;
  }
</style>
